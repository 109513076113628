<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">{{ $t("tags.tags") }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            :aria-label="$t(`orderChange.close`)"
            v-on:click="$emit('update:show', false)"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row custom-input whitebg-input mb-1">
            <div class="col-12 mb-3">
              <input
                type="text"
                class="form-control"
                v-model="q"
                ref="imp"
                :placeholder="$t(`tags.searchOrAddNew`)"
              />
            </div>
            <div class="col-12">
              <div class="fs-6">
                <span
                  v-for="tag in tags.filter(
                    (o) => o.name.toLowerCase().indexOf(q.toLowerCase()) > -1
                  )"
                  :key="tag.id"
                  class="badge me-1 mb-2"
                  :class="{
                    'badge-outline': modelValueIds.indexOf(tag.id) === -1,
                  }"
                  v-on:click="addTag(tag)"
                >
                  {{ tag.name }}
                </span>
                <span
                  v-if="modelValueNames.indexOf(q.toLowerCase()) === -1"
                  class="badge badge-outline"
                  v-on:click="addNewTag(q)"
                >
                  {{ q }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import http from "../../modules/http";
const bootstrap = require("bootstrap");

export default {
  name: "Tags",
  props: {
    modelValue: Object,
    show: Boolean,
    ajaxUrl: {
      type: String,
      default: null,
    },
    id: Number,
  },
  emits: ["update:modelValue", "update:show", "change"],
  data: function () {
    return {
      q: "",
      value: this.modelValue,
      modelValueIds: this.modelValue.map((o) => o.id),
      modelValueNames: this.modelValue.map((o) => o.name.toLowerCase()),
      isAdding: false,
    };
  },
  mounted() {
    let myModal = new bootstrap.Modal(this.$refs.modal, {
      backdrop: "static",
    });
    if (this.show) {
      myModal.show();
    } else {
      myModal.hide();
    }
  },
  computed: {
    tags() {
      const store = useStore();
      return store.state.tags;
    },
  },
  watch: {
    show() {
      let myModal = new bootstrap.Modal(this.$refs.modal, {
        backdrop: "static",
      });
      if (this.show) {
        myModal.show();
      } else {
        myModal.hide();
      }
    },
  },
  methods: {
    addNewTag(tag) {
      if (this.isAdding) {
        return;
      }
      this.isAdding = true;
      http
        .fetch(
          this.ajaxUrl === null || this.ajaxUrl === undefined
            ? "/tags"
            : this.ajaxUrl,
          { name: tag }
        )
        .then((data) => {
          this.addTag({ name: tag, id: data.id });
          this.isAdding = false;
        });
    },
    addTag(tag) {
      let e = this.modelValueIds.indexOf(tag.id);
      if (e === -1) {
        this.value.push(tag);
        this.modelValueIds.push(tag.id);
        this.modelValueNames.push(tag.name.toLowerCase());
      } else {
        this.value.splice(e, 1);
        this.modelValueIds.splice(e, 1);
        this.modelValueNames.splice(e, 1);
      }
      this.$emit("update:modelValue", this.value);
      this.$emit("change");
    },
    closeModal() {
      this.$emit("update:show", false);
      this.save();
    },
  },
  components: {},
};
</script>
